import React, { Suspense } from "react";
import LoadingScreen from "./LoadingScreen";
import Fragments from "./Fragments";

function PageFragments() {
  return (
    <div className="font-font-family">
      {" "}
      <Suspense fallback={<LoadingScreen />}>
        <Fragments />
      </Suspense>
    </div>
  );
}

export default PageFragments;
