import React, { Suspense } from "react";
import LoadingScreen from "./LoadingScreen";
import Exhibition from "./Exhibition";

function PageExhibition() {
  return (
    <div className="font-font-family">
      {" "}
      <Suspense fallback={<LoadingScreen />}>
        <Exhibition />
      </Suspense>
    </div>
  );
}

export default PageExhibition;
