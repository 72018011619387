import Detail from "./media/traces/detail.webp"
import RomeDetail from "./media/traces/romedetail.webp"
import AntwerpDetail from "./media/traces/antwerpdetail.webp"
function Traces() {
  return (
    <div className=" bg-black">
      <div>
        <section class="text-white font-font-family">
          <div class="text-center w-full mt-20 mb-20">
            <h1 class="md:text-8xl text-5xl italic  mb-4 text-white">
              Traces
            </h1>
          </div>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={Detail}
              alt="Detail"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Detail
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={RomeDetail}
              alt="Detail, Rome"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
          Rome, Detail
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={AntwerpDetail}
              alt="Antwerp , Detail"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
          Antwerp, Detail
          </h1>
        </section>
      </div>
    </div>
  );
}

export default Traces;
