import Navbar from "./Navbar";
import Footer from "./Footer";
import FL1L from "./media/fragments/FL1L.webp";
import FL2L from "./media/fragments/FL2L.webp";
import FL3L from "./media/fragments/FL3L.webp";
import FL4L from "./media/fragments/FL4L.webp";
import FL5L from "./media/fragments/FL5L.webp";
import FL6L from "./media/fragments/FL6L.webp";
import FL7L from "./media/fragments/FL7L.webp";
import FL8L from "./media/fragments/FL8L.webp";
import FH1H from "./media/fragments/FH1H.webp";
import FH2H from "./media/fragments/FH2H.webp";
function Fragments() {
  return (
    <div className=" bg-black">
      <Navbar />
      <div>
        <section class="text-white font-font-family">
          <div class="text-center w-full mt-20 mb-20">
            <h1 class="md:text-8xl text-5xl italic  mb-4 text-white">
              Fragments
            </h1>
          </div>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={FL1L}
              alt="Run, Rome"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Run, Rome
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={FL2L}
              alt="Toledy Sky, Toledo"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Toledy Sky, Toledo
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={FL4L}
              alt="Grafitti, Malaga"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Grafitti, Malaga
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={FL3L}
              alt="shades, Rome "
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            shades, Rome
          </h1>
          <div className="grid grid-cols-2 md:space-x-8 space-x-2 md:ml-14 ml-4 lg:flex justify-center md:mr-14 mr-4 mt-14">
            <div className=" col-span-1 max-w-fit max-h-fit">
              <img
                className=" md:h-132 h-64 md:w-132 w-auto   "
                src={FH1H}
                alt="San Jose, Rome"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 ">
                San Jose, Rome
              </h1>
            </div>
            <div className=" col-span-1">
              <img
                className="md:h-132  h-64  md:w-132 w-auto "
                src={FH2H}
                alt="Love, Rome"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 md:-ml-12">
                Love, Rome
              </h1>
            </div>
          </div>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={FL5L}
              alt="Maschio Dominante, Rome"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Maschio Dominante, Rome
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={FL6L}
              alt="Mix, Rome"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Mix, Rome
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={FL7L}
              alt="Malagueta, Malaga"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Malagueta, Malaga
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={FL8L}
              alt="Street, Rome"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Street, Rome
          </h1>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Fragments;
