import SH1 from "./media/solitude/SH1.webp";
import SH2 from "./media/solitude/SH2.webp";
import SH3 from "./media/solitude/SH3.webp";
import SL1 from "./media/solitude/SL1.webp";
import SL2 from "./media/solitude/SL2.webp";
import SL3 from "./media/solitude/SL3.webp";
import SL4 from "./media/solitude/SL4.webp";
import SL5 from "./media/solitude/SL5.webp";
import SL6 from "./media/solitude/SL6.webp";
import SL7 from "./media/solitude/SL7.webp";
function Solitude() {
  return (
    <div className=" bg-black">
      <div>
        <section class="text-white font-font-family">
          <div class="text-center w-full mt-20 mb-20">
            <h1 class="md:text-8xl text-5xl italic  mb-4 text-white">
              Solitude
            </h1>
          </div>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={SL1}
              alt="Detail, Antwerp"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Detail, Antwerp
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={SL2}
              alt="Detail, Antwerp"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
          Detail, Antwerp
          </h1>
          <div className="grid grid-cols-2 md:space-x-8 space-x-2 md:ml-14 ml-4 lg:flex justify-center md:mr-14 mr-4 mt-14">
            <div className=" col-span-1 max-w-fit max-h-fit">
              <img
                className=" md:h-132 h-64 md:w-132 w-auto   "
                src={SH2}
                alt="Detail, Salamanca"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 ">
                Detail, Salamanca
              </h1>
            </div>
            <div className=" col-span-1">
              <img
                className="md:h-132  h-64  md:w-132 w-auto "
                src={SH3}
                alt="Detail, Florence"
              />
              <h1 className=" text-center md:text-4xl text-sm md:mt-6 mt-2 md:-ml-12">
                Detail, Florence
              </h1>
            </div>
          </div>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={SL4}
              alt="Detail, Venice"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
          Detail, Venice
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={SL5}
              alt="Detail, Porto Beach"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
          Detail, Porto Beach
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={SL6}
              alt="Birds, Florence"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
            Birds, Florence
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={SL7}
              alt="Detail, Verona"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
          Detail, Verona
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={SL3}
              alt="Detail, Treviso"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
          Detail, Treviso
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={SH1}
              alt="Detail, The Netherlands"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-base md:mt-8 mt-4 ">
          Detail, The Netherlands
          </h1>
        </section>
      </div>
    </div>
  );
}

export default Solitude;
