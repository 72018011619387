import React, { Suspense } from "react";
import Navbar from "./Navbar";
import LoadingScreen from "./LoadingScreen";
import Solitude from "./Solitude";
import Footer from "./Footer";

function PageSolitude() {
  return (
    <div className="font-font-family">
      {" "}
      <Navbar />
      <Suspense fallback={<LoadingScreen />}>
        <Solitude />
      </Suspense>
      <Footer />
    </div>
  );
}

export default PageSolitude;
