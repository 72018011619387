import Navbar from "./Navbar";
import Footer from "./Footer";
import EL1L from "./media/exhibition/EL1L.webp";
import EL2L from "./media/exhibition/EL2L.webp";
import EL3L from "./media/exhibition/EL3L.webp";
import EL4L from "./media/exhibition/EL4L.webp";
import EL5L from "./media/exhibition/EL5L.webp";
import EL6L from "./media/exhibition/EL6L.webp";
import EL7L from "./media/exhibition/EL7L.webp";
import EL8L from "./media/exhibition/EL8L.webp";
import EL9L from "./media/exhibition/EL9L.webp";
import EL10L from "./media/exhibition/EL10L.webp";
import EL11L from "./media/exhibition/EL11L.webp";
import EL12L from "./media/exhibition/EL12L.webp";
import EH1H from "./media/exhibition/EH1H.webp";
import EH2H from "./media/exhibition/EH2H.webp";
import EH3H from "./media/exhibition/EH3H.webp";
import EH4H from "./media/exhibition/EH4H.webp";
import EH5H from "./media/exhibition/EH5H.webp";
import EH6H from "./media/exhibition/EH6H.webp";
import EH7H from "./media/exhibition/EH7H.webp";
function Exhibition() {
  return (
    <div className=" bg-black">
      <Navbar />
      <div>
        <section class="text-white font-font-family">
          <div class="text-center w-full mt-20 mb-20">
            <h1 class="md:text-8xl text-5xl italic  mb-4 text-white">
              Visible & Invisible
            </h1>
            <div className="grid grid-cols-2 md:mt-20 mt-20 md:space-x-8 space-x-2 md:ml-14 ml-4 lg:flex justify-center  md:mr-14 mr-4">
              <div className=" col-span-1 max-w-fit max-h-fit">
                <img
                  className=" md:h-132 h-64 md:w-132 w-auto   "
                  src={EH1H}
                  alt="Detail, Rome"
                />
                <h1 className=" text-center md:text-4xl text-lg md:mt-6 mt-2 ">
                  Detail, Rome
                </h1>
              </div>
              <div className=" col-span-1">
                <img
                  className="md:h-132  h-64  md:w-132 w-auto "
                  src={EH2H}
                  alt="Street, Florence"
                />
                <h1 className=" text-center md:text-4xl text-lg md:mt-6 mt-2 md:-ml-12">
                  Street, Florence
                </h1>
              </div>
            </div>
            <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
              <img
                className=" object-cover  md:w-134 w-130"
                src={EL1L}
                alt="Street Perspective,Paris"
              />
            </div>
            <h1 className="text-center  md:text-4xl text-xl md:mt-8 mt-4 ">
              Street Perspective,Paris
            </h1>
            <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
              <img
                className=" object-cover  md:w-134 w-130"
                src={EL2L}
                alt="Lights on a rainy night, Paris"
              />
            </div>
            <h1 className="text-center  md:text-4xl text-xl md:mt-8 mt-4 ">
              Lights on a rainy night, Paris
            </h1>
            <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
              <img
                className=" object-cover  md:w-134 w-130"
                src={EL3L}
                alt="Porto Street"
              />
            </div>
            <h1 className="text-center  md:text-4xl text-xl md:mt-8 mt-4 ">
              Porto Street
            </h1>
            <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
              <img
                className=" object-cover  md:w-134 w-130"
                src={EL4L}
                alt="Birds, Florence"
              />
            </div>
            <h1 className="text-center  md:text-4xl text-xl md:mt-8 mt-4 ">
              Birds, Florence
            </h1>
          </div>
          <div className="grid grid-cols-2  md:mt-8 mt-4 md:space-x-8 space-x-2 md:ml-14 ml-4 lg:flex justify-center  md:mr-14 mr-4">
            <div className=" col-span-1 max-w-fit max-h-fit">
              <img
                className=" md:h-132 h-64 md:w-132 w-auto   "
                src={EH3H}
                alt="Emperor, Rome"
              />
              <h1 className=" text-center md:text-4xl text-lg md:mt-6 mt-2 ">
                Emperor, Rome
              </h1>
            </div>
            <div className=" col-span-1">
              <img
                className="md:h-132  h-64  md:w-132 w-auto "
                src={EH4H}
                alt="Shadow, Paris"
              />
              <h1 className=" text-center md:text-4xl text-lg md:mt-6 mt-2 md:-ml-12">
                Shadow, Paris
              </h1>
            </div>
          </div>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={EL5L}
              alt="Metro sign, Paris"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-xl md:mt-8 mt-4 ">
            Metro sign, Paris
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={EL6L}
              alt="Hyper - Reality, Paris"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-xl md:mt-8 mt-4 ">
            Hyper - Reality, Paris
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={EL7L}
              alt="Shiny Facade, Paris"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-xl md:mt-8 mt-4 ">
            Shiny Facade, Paris
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={EL8L}
              alt="Decorated Toledo"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-xl md:mt-8 mt-4 ">
            Decorated Toledo
          </h1>
          <div className=" flex justify-center md:mt-8 mt-4">
            <img
              className=" md:h-132  h-96  md:w-132 w-auto"
              src={EH5H}
              alt="II Duomo, Florence "
            />
          </div>
          <h1 className=" text-center md:text-4xl text-xl md:mt-6 mt-2 ">
            II Duomo, Florencecd
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={EL9L}
              alt="Foto Automatica"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-xl md:mt-8 mt-4 ">
            Foto Automatica
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={EL10L}
              alt="Porto - Detail"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-xl md:mt-8 mt-4 ">
            Porto - Detail
          </h1>
          <div className="grid grid-cols-2 md:mt-20 mt-20 md:space-x-8 space-x-2 md:ml-14 ml-4 lg:flex justify-center  md:mr-14 mr-4">
            <div className=" col-span-1 max-w-fit max-h-fit">
              <img
                className=" md:h-132 h-64 md:w-132 w-auto   "
                src={EH6H}
                alt="Dom Luis I Bridge"
              />
              <h1 className=" text-center md:text-4xl text-lg md:mt-6 mt-2 ">
                Dom Luis I Bridge
              </h1>
            </div>
            <div className=" col-span-1">
              <img
                className="md:h-132  h-64  md:w-132 w-auto "
                src={EH7H}
                alt="Morning View Detail - Turkey"
              />
              <h1 className=" text-center md:text-4xl text-lg md:mt-6 mt-2 md:-ml-12">
                Morning View Detail - Turkey
              </h1>
            </div>
          </div>

          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={EL11L}
              alt="Firenze Black and White"
            />
          </div>
          <h1 className="text-center  md:text-4xl text-xl md:mt-8 mt-4 ">
            Firenze Black and White
          </h1>
          <div className="flex justify-center md:mt-8 mt-4 md:ml-14 ml-4 md:mr-14 mr-4">
            <img
              className=" object-cover  md:w-134 w-130"
              src={EL12L}
              alt="Kayseri Detail - Turkey"
            />
          </div>
          <h1 className=" text-center md:text-4xl text-xl md:mt-6 mt-2 ">
            Kayseri Detail - Turkey
          </h1>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Exhibition;
