import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";
import Home from "./Home";
import PageTraces from "./PageTraces";
import PageBlack from "./PageBlack";
import PageFragments from "./PageFraments";
import PageExhibition from "./PageExhibition";
import PageSolitude from "./PageSolitude";
function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1900);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="bg-black">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />+
              <Route path="/traces" element={<PageTraces />} />
              <Route path="/blackandwhite" element={<PageBlack />} />
              <Route path="/fragments" element={<PageFragments />} />
              <Route path="/exhibition" element={<PageExhibition />} />
              <Route path="/solitude" element={<PageSolitude />} />
            </Routes>
          </BrowserRouter>
        </div>
      )}
    </>
  );
}

export default App;
